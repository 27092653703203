
















import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    folder: Object
  }
})

export default class ATSComponentsImgLabelAside extends Vue {
  isShow: boolean = false
  scale: boolean = false
  listIndex: number = -1

  isFold () {
    this.isShow = !this.isShow
  }
  imgInfo (folderInfo, index) {
    this.listIndex = index
    this.$emit('getImgInfo', folderInfo)
  }
}
