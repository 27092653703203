






















































import { Component, Vue } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'
import { Getter, Action } from 'vuex-class'
import dcmAside from '@/components/imgLabel/dcmAside.vue'

@Component({
  mixins: [globalMixins],
  components: {
    dcmAside
  }
})
export default class imgLabelIndex extends Vue<globalMixins> {
  @Getter('dcmDetail') dcmDetail: any
  @Getter('dcmAsideLists') dcmAsideLists: any[]
  @Action('dcmCaseDetail') dcmCaseDetail: Function
  @Action('dcmLabels') dcmLabels: Function
  @Action('dcmLabelStatus') dcmLabelStatus: Function

  caseId: number = 0
  project: any = JSON.parse(localStorage.getItem("projects"))
  account: any =  JSON.parse(localStorage.getItem("account"))
  asideLists: any[]
  imageData: any = {
    raw_img: '',
    out_img: '',
    out_labels: [],
    raw_title: '原图',
    out_title: '标注图'
  }
  currentFieldId: number = 0
  patientInfo: any = {
    AccessionNumber: '',
    AcquisitionDate:'',
    AcquisitionDateTime: '',
    BodyPartExamined: '',
    Columns: '',
    Modality: '',
    PatientAge: '',
    PatientBirthDate: '',
    PatientID: '',
    PatientName: '',
    PatientSex: '',
    PixelSpacing: [0, 0],
    ProtocolName: '',
    Rows: '',
    SeriesDescription: '',
    SeriesNumber: '',
    SliceLocation: '',
    StudyDescription: ''
  }
  created () {
    this.caseId = Number(this.$route.params.id)
    this.getDcmDetail()
  }
  back () {
    this.$router.push('/img-label')
  }
  goToCaseDetail (caseId) {
    let detail: any = this.dcmDetail
    let creatorId: number = detail.creator_id
    let accountId: number = this.account.id
    // 判断该病例是不是自己创建的
    let isMineCreate: boolean = (creatorId === accountId)
    // 判断用户角色
    let isDoctor: boolean = (this.project.role === 'doctor')
    /**
     * 大体分为2种情况
     * 组员: 跳转到我的病例里面的4种状态
     * 管理员 (leader, professor, staff),如果是草稿，就是自己的草稿，跳转到我的病例-草稿; 其他的跳转到审核 + 通过
     */
    if (isDoctor) {
      this.judgeLinkTo(caseId, '1')
    } else {
      if (detail.status === 'draft' && isMineCreate) {
        this.judgeLinkTo(caseId, '1')
      } else if(detail.status === 'draft' && !isMineCreate) {
        this.errorMsg('无法查看该组员的草稿箱病例！')
      } else {
        this.judgeLinkTo(caseId, '0')
      }
    }
  }
  judgeLinkTo (caseId, isMine) {
    let { href } = this.$router.resolve({  
      name: 'case-detail',
      params: {
        id: caseId
      },
      query: {
        'mine': isMine
      }
    })
    window.open(href, '_blank')
  }
  saveLabelInfo (data) {
    let query: any[] = []
    Object.keys(data).map(key => {
      if (key === 'lineList' && data[key].length !== 0) {
        data[key].map((item) => {
          query.push({
            type: 'line',
            ...item
          })
        })
        
      } else if (key === 'boxList' && data[key].length !== 0) {
        data[key].map((item) => {
          query.push({
            type: 'rectangle',
            ...item
          })
        })
      } else if (key === 'angleList' && data[key].length !== 0) {
        data[key].map((item) => {
          query.push({
            type: 'angle',
            ...item
          })
        })
      }
    })
    let params = {
      projectId: this.project.id,
      caseId: this.caseId,
      fieldId: this.currentFieldId,
      query
    }
    this.dcmLabels({ params }).then(() => {
        this.successMsg('标注保存成功！')
        this.getDcmDetail()
    })
  }
  getImgInfo (folderInfo) {
    this.patientInfo = folderInfo.data.info
    this.currentFieldId = folderInfo.id
    this.imageData = {
      raw_img: folderInfo.data.img_256,
      out_img: folderInfo.data.img_256,
      out_labels: folderInfo.labels,
      raw_title: '原图',
      out_title: '标注图'
    }
  }
  getDcmDetail () {
    let params = {
      projectId: this.project.id,
      caseId: this.caseId
    }
    this.dcmCaseDetail({ params })
  }
  doFinish () {
    let params = {
      projectId: this.project.id,
      caseId: this.caseId,
      status: 'finished'
    }
    this.$confirm('是否提交病例为已完成标注?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.dcmLabelStatus({ params }).then(() => {
        this.successMsg('已转化为已完成标注!')
      })
    })
  }
  doPending () {
    let params = {
      projectId: this.project.id,
      caseId: this.caseId,
      status: 'pending'
    }
    this.$confirm('是否提交病例为保持标注中?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.dcmLabelStatus({ params }).then(() => {
        this.successMsg('已转化为保持标注中!')
      })
    })
  }
}
